@import "../mixins";

$menu-width: 385px;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			left: 0;
			display: inline-block;
			float: left;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: white;
			opacity: 1;
			transform: translateX(0);
			overflow-y: auto;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateX(-$menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				display: none;
			}
            &.mobile-offset {

            }
			>.heading {
				height: 91px;
				box-sizing: border-box;
				padding: 13px 15px 13px;
				a.close {
					width: 45px;
					height: 45px;
					line-height: 45px;
					background-color: $bg-secondary;
					border-color: $bg-secondary;
					padding: 0;
					text-align: center;
					svg {
						vertical-align: middle;
					}
				}
			}	
			.main-body {
				display: block;
				overflow-y: auto;
			    overflow-x: hidden;
			    padding-top: 0;
				height: calc(100vh - 91px);
				box-sizing: border-box;
				padding: 0 15px;
			}
            /*main body contents*/
			nav.mobile-main-menu-nav {
				display: inline-block;
				width: 100%;
				margin: 0 0 14px;
				ul {
					width: 100%;
					padding: 0;
					margin: 0;
					list-style: none;
					li {
						font-size: 12px;
						font-weight: 400;
						line-height: 140%;
						margin: 0 0 15.3px;
						&.current { 

							a { 
								&:after {
									opacity: 1;
								}
							}
						}
						a {
							display: inline-block;
							color: $gray-base;
							position: relative;
							&:after {
								content: "";
								display: inline-block;
								width: 100%;
								position: absolute;
								left: 0;
								bottom: 0;
								border-bottom: 1px solid $brand-primary;
								opacity: 0;
								transition: all 300ms linear;
							}
						}
					}
				}
			}

			.messengers { 
				display: inline-block;
				width: 100%;
				margin: 0 0 28px;
                .messenger { 
                    float: left;
                    margin-left: 31px;
                    &:first-of-type {
                        margin: 0;
                    }
                    a { 
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        .icon { 
                            float: left;
                            color: $gray-base;
                            margin-right: 10px;
                            height: 18px;
                            svg {
                                height: 100%;
                                width: auto;
                            }
                        }

                        .text { 
                            float: left;
                            color: $gray;
                        }
                    }
                }
            }

			.contacts { 
                float: left;
                .contacts-holder { 

                    .contacts-inner-block { 

                        .contact-info { 
                            width: 100%;
							display: inline-block;
							margin: 0 0 9px;
							&.phone {
								margin: 28px 0 0;
							}
                            .contact-info-holder { 

                                a { 
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 18px;
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                    .icon { 
                                        float: left;
                                        height: 18px;
                                        margin-right: 10px;
										width: 18px;
                                        svg { 
                                            height: 100%;
                                            width: auto;
                                        }
                                    }

                                    .text { 
                                        float: left;
										width: calc(100% - 18px - 10px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

			.action {
				float: left;
				margin: 26px 0 0;
				width: 100%;
                .btn { 
                    width: 100%;
                    text-align: center;
                }
            }
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";
