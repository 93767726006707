.general-popup.fancybox__content {
    padding: 0;
    overflow: initial;
}

.fancybox__container {
    z-index: 10000 !important;
}

.fancybox__container .fancybox__backdrop {
    z-index: 10001;
}

.fancybox__container .fancybox__carousel {
    z-index: 10002;
}

.popup {
    position: relative;
    .custom-close {
        position: absolute;
        right: 0;
        top: 0;
        a {
            display: inline-block;
            text-decoration: none;
            background-color: $brand-primary;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            svg {
                width: 13px;
                height: 13px;
                color: white;
                vertical-align: middle;
                transition: all 300ms;
            }
            &:hover {
                background-color: $brand-primary-accent;
            }
        }
    }
}

.general-popup-container {
    display: inline-block;
    max-width: 595px;
    box-sizing: border-box;
    padding: 50px 40px;
    .general-popup-container-holder {
        display: inline-block;
        width: 100%;

        .success { 
            display: inline-block;
            width: 100%;
            .icon { 
                display: inline-block;
                width: 100%;
                text-align: center;
                margin: 0 0 30px;
                .icon-block { 
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    background-color: $bg-secondary;
                    text-align: center;
                    svg { 
                        width: 33px;
                        height: 23px;
                        vertical-align: middle;
                    }
                }
        
               
            }

            .text { 
                display: inline-block;
                width: 100%;
                text-align: center;
            }
        }

        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            text-align: center;
            h3, .h3 {

            }
            
            .note {
                display: inline-block;
                width: 100%;
                text-align: center;
                color: $gray;
                font-size: 16px;
                line-height: 140%;
                font-weight: 400;
                margin: 12px 0 0;
            }
        }

        .form { 

            form { 
        
                .form-group { 
        
                    .form-col { 
        
                        
                    }
                }

                .form-field { 
                    margin: 0 0 11px;
                    label.input { 

                        .name { 

                        }

                        input, textarea { 
                            background-color: $bg-secondary;
                        }

                        textarea {
                            height: 140px;
                        }
                    }

                    label.checkcontainer {
                        margin: 15px 0 0;
                    }

                    label.rating {
                        margin: 15px 0 0;
                    }
                }
        
                .action { 
                    margin: 20px 0 0;
                    .submit { 
        
                        .btn { 
        
                        }
                    }
        
                    .agree-col { 
        
                        a { 
        
                        }
                    }
                }
            }
        }

    }
}

.review-text-full { 
    display: inline-block;
    max-width: 720px;
    box-sizing: border-box;
    padding: 30px 30px;
    background-color: $bg-secondary;
	.review-text-full-holder { 
        display: inline-block;
        width: 100%;
        .author { 
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0 0 17px;
            .image { 
                width: 65px;
                height: 65px;
                border-radius: 50%;
                overflow: hidden;
                font-size: 28px;
                background-color: $brand-primary;
                color: white;
                line-height: 65px;
                text-align: center;
                img { 
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .stats { 
                width: calc(100% - 65px);
                box-sizing: border-box;
                padding-left: 15px;
                margin: 4px 0 0;
                .name { 
                    width: 100%;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 120%;
                    text-align: left;
                    margin: 0 0 5px;
                }

                .score { 
                    width: 100%;
                    .stars { 
                        width: 100%;
                        margin-left: -1px;
                        &.stars-1 {
                            .star:first-of-type {
                                color: $brand-primary;
                            }
                        }
                        &.stars-2 {
                            .star:nth-of-type(1),
                            .star:nth-of-type(2)  {
                                color: $brand-primary;
                            }
                        }
                        &.stars-3 {
                            .star:nth-of-type(1),
                            .star:nth-of-type(2),
                            .star:nth-of-type(3)  {
                                color: $brand-primary;
                            }
                        }
                        &.stars-4 {
                            .star:nth-of-type(1),
                            .star:nth-of-type(2),
                            .star:nth-of-type(3),
                            .star:nth-of-type(4)  {
                                color: $brand-primary;
                            }
                        }
                        &.stars-5 {
                            .star {
                                color: $brand-primary;
                            }
                        }
                        .star { 
                            float: left;
                            width: 18px;
                            height: 17px;
                            margin: 0 1.5px;
                            color: $gray-light;
                            svg { 
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
		}

		.review-text { 
            display: inline-block;
            width: 100%;
            max-height: 75vh;
            overflow-y: auto;
			.text { 
                display: inline-block;
                width: 100%;
                color: $gray;
				p { 
                    font-size: 14px;
                    line-height: 140%;
                    &:not(:last-of-type) {
                        margin: 0 0 15px;
                    }
				}
			}
		}
	}
}

@import "../../media/tablet/includes/popups/custom_fancybox";
@import "../../media/mobile/includes/popups/custom_fancybox";
