@media (max-width: $screen-sm) {
    // sm-view
    form {
        .action {
            .agree-col {
                font-size: 10px;
            }
        }
        .action.full {
            .submit {
                margin: 0 0 7px;
            }
        }


        .form-field {
            label.input {
                &.addon {
                    input {
                        padding: 0px 42px 0 14px;
                    }
                }
                
                input, textarea {
                    font-size: 12px;
                    @include placeholder {
                        font-size: 12px;
                    }
                }
            }

            label.input.custom-select-search {
                .addon { 
                    .btn {
              
                    }
                }

                .results {
                    .results-holder {
                        .inner-results {
                            max-height: 120px;
                        }
                    }
                }
            }

            label.checkcontainer {
                .name {
                    font-size: 10px;
                }
            }

            label.rating {
                .name {
                    font-size: 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
