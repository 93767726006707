@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .general-popup-container {
        max-width: 505px;
        .general-popup-container-holder {
            .success { 
                .icon { 
                    margin: 0 0 15px;
                    .icon-block { 
                        width: 80px;
                        height: 80px;
                        line-height: 80px;
                    }
                }
            }
            
            .heading {
                .note {
                    font-size: 14px;
                    margin: 7px 0 0;
                }
            }
        }
    }

    .review-text-full {
        max-width: 690px;
        .review-text-full-holder {
            .author { 
                .image {

                }

                .stats { 
                    .name {
                        font-size: 16px;
                    }

                    .score {

                    }
                }
            }

            .review-text { 
                .text { 
                    p {
                        font-size: 12px;
                        &:not(:last-of-type) {
                            margin: 0 0 12px;
                        }
                    }
                }
            }
        }
    }
}
