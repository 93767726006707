@media (max-width: $screen-sm) {
    // sm-view
    .btn {
        font-size: 14px;
        padding: 13px 14px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
