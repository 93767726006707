@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder { 
        .header-top { 
            .header-top-holder { 
                .contacts { 
                    position: relative;
                    display: inline-block;
                    margin-right: 36px;
                    &:hover {
                        .contacts-legend {
                            .icon {
                                svg {
                                    transform: rotate(180deg);
                                    transition: all 300ms;
                                }
                            }
                        }
                        .contacts-holder { 
                            max-height: 300px;
                            transition: all 600ms;
                        }
                    }
                    .contacts-legend {
                        font-size: 14px;
                        line-height: 140%;
                        .text {
                            float: left;
                        }

                        .icon {
                            float: left;
                            width: 8px;
                            height: 4px;
                            padding: 8px 0 0;
                            margin-left: 6px;
                            svg {
                                width: 100%;
                                height: 100%;
                                transition: all 300ms;
                            }
                        }
                    }
                    .contacts-holder { 
                        position: absolute;
                        left: -30px;
                        top: 100%;
                        width: 405px;
                        max-height: 0;
                        overflow: hidden;
                        transition: all 600ms;
                        z-index: 1002;
                        padding: 4px 0 0;
                        .contacts-inner-block { 
                            display: inline-block;
                            width: 100%;
                            background-color: white;
                            box-shadow: 0px 4px 20px 0px #00000014;
                            box-sizing: border-box;
                            padding: 31px 30px 22px;
                            .contact-info { 
                                width: 100%;
                                margin: 0 0 10px;
                                &.phone {
                                    margin: 30px 0 0;
                                    .contact-info-holder {
                                        a {
                                            font-size: 16px;
                                            font-weight: 700;
                                        }
                                    } 
                                }
                                .contact-info-holder { 

                                    a { 
                                        font-size: 14px;
                                        line-height: 140%;
                                    }
                                }
                            }
                        }
                    }
                }

                .work-hours { 
                    .info { 

                        .info-holder { 
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }
                }
            }
        }

        .header-main { 
            .header-main-holder { 
                .menu { 
                    margin-left: 31px;
                    padding: 44px 0 0;
                    .menu-holder { 

                        nav { 
    
                            ul { 
                                margin-left: -10.5px;
                                li {
                                    padding: 0 10.5px;
                                    a {
                                        &:after {
                                            bottom: -1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .action { 
                    .btn {
                        width: 262px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .header-holder { 
        .header-main {
            .header-main-holder { 
                .left {

                }

                .right {

                }

                .mobile-menu { 

                }

                .logo {
                    margin-left: 29px;
                    .logo-holder { 
                        padding: 18px 0 0;
                        a {
                            svg {
                                width: 45px;
                                height: auto;
                            }
                        }
                    }
                }

                .action { 
                    padding: 20px 0 0;
                    .btn {
                        width: 221px;
                    }
                }
            }
        }
    }
}
