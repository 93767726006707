@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    form {
        .form-field {
            label.input {
                input, textarea {
                    height: 45px;
                    font-size: 14px;
                    @include placeholder {
                        font-size: 14px;
                    }
                }
            }

            label.input.custom-select-search {
                .addon { 
                    .btn {
                        width: 29px;
                        height: 29px;
                    }
                }
            }

            label.rating {
                .name {
                    font-size: 14px;
                }
            }
        }
    }
}
