@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder { 
                display: flex;
                flex-direction: column-reverse;
                .left {
                    width: 100%;
                    display: flex;
                    flex-direction: column-reverse;
                    .general-info {
                        width: 100%;
                        .general-info-holder { 
                            .company { 

                            }

                            .socials { 

                            }

                            .copyright { 
                                font-size: 10px;
                                margin-bottom: 8px;
                            }

                            .other { 
                                font-size: 10px;
                                .policy {
                                    margin: 0 0 16px;
                                }

                                .studio { 
                                    a {
                                        .text {

                                        }

                                        .icon {
                                            svg {
                                                width: 34px;
                                                height: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .menu { 
                        margin: 0 0 20px;
                        padding: 0;
                        .menu-holder {
                            nav {
                                ul {
                                    column-count: 2;
                                    column-gap: 16px;
                                    li {
                                        a {

                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    width: 100%;
                    margin: 0 0 29px;
                }
            }
        }
    }

    .call-order-form {
        .call-order-form-holder { 
            .heading { 
                margin: 0 0 1px;
                h2, .h2 {

                }
            }

            .form {

                form { 
                    .form-field { 
                        margin-bottom: 16px;
                        .input { 
    
                            .name { 
    
                            }
    
                            input {
                                padding: 0 14px 0 14px;
                            }
                        }
                    }

                    .action {
                        .submit {
                            .btn {
                                padding: 12px 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
