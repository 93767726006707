.footer-holder { 
    display: inline-block;
    width: 100%;
	.footer-main { 
        display: inline-block;
        width: 100%;
		.footer-main-holder { 
            display: inline-block;
            width: 100%;
            padding: 79px 0 0;
			.left { 
                float: left;
				.general-info { 
                    float: left;
                    width: 218px;
					.general-info-holder { 
                        display: inline-block;
                        width: 100%;
						.company { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 29px;
							a { 

								svg { 

								}
							}
						}

						.socials { 
                            display: inline-block;
                            width: 100%;
                            margin-left: -7.5px;
                            margin-bottom: 28px;
							.social { 
                                float: left;
                                margin: 0 7.5px;
								a { 
                                    display: block;
                                    width: 24px;
                                    height: 24px;
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
									svg { 
                                        width: 100%;
                                        height: 100%;
									}
								}
							}
						}

						.copyright { 
                            display: inline-block;
                            width: 100%;
                            font-size: 14px;
                            line-height: 140%;
                            color: $gray;
                            margin-bottom: 32px;
							br { 

							}
						}

						.other { 
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: calc(100% + 279px);
                            font-size: 14px;
                            line-height: 140%;
							.policy { 
                                float: left;
								a { 

								}
							}

							.studio { 
                                float: left;
								a { 
                                    color: $gray-light;
                                    display: flex;
                                    align-items: center;
                                    &:hover {
                                        color: $brand-primary;
                                    }
									.text { 
                                        float: left;
									}

									.icon { 
                                        float: left;
                                        margin-left: 10px;
										svg { 

										}
									}
								}
							}
						}
					}
				}

				.menu { 
                    float: left;
                    margin-left: 90px;
                    padding: 3px 0 0;
					.menu-holder { 

						nav { 

							ul { 
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                column-count: 2;
                                column-gap: 17px;
								li { 
                                    font-size: 18px;
                                    line-height: 120%;
                                    margin: 0 0 18px;                                 
									a { 
                                        display: inline-block;
                                        color: $gray-base;
                                        position: relative;
                                        &:hover {
                                            &:after {
                                                opacity: 1;
                                                transform: translateY(0px);
                                                transition: all 300ms;
                                            }
                                        }
                                        &:after {
                                            content: "";
                                            display: inline-block;
                                            width: 100%;
                                            border-top: 1px solid $brand-primary;
                                            position: absolute;
                                            left: 0;
                                            bottom: -2px;
                                            opacity: 0;
                                            transform: translateY(2px);
                                            transition: all 300ms;
                                        }
									}
								}
							}
						}
					}
				}
			}

			.right { 
                float: right;
                width: 575px;
			}
		}
	}
}

.call-order-form { 
    display: inline-block;
    width: 100%;
    .call-order-form-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            h2, .h2 { 

            }

            .note { 
                display: inline-block;
                width: 100%;
                color: $gray;
                margin: 15px 0 0;
                br { 

                }
            }
        }

        .form {

            form { 
                display: inline-block;
                width: 100%;
                .form-group { 

                    .form-col { 

                        
                    }
                }

                .form-field { 

                    .input { 

                        .name { 

                        }

                        input { 
                            background-color: $bg-secondary;
                        }
                    }
                }

                .action {
                    width: 100%;
                    .submit {
                        width: 100%;
                        .btn {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

            }
        }
    }
}

@import "../../media/tablet/includes/base/footer";
@import "../../media/mobile/includes/base/footer";
