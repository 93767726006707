@media (max-width: $screen-sm) {
    // sm-view
    .header-holder { 
        .header-main {
            .header-main-holder { 
                .left {
                    width: 100%;
                }
                .mobile-menu { 
                    padding: 13px 0 0;
                }

                .logo {
                    float: right;
                    .logo-holder { 
                        padding: 14px 0 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
