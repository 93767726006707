@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder { 
                padding: 60px 0 0;
                .left { 
                    .general-info { 
                        .general-info-holder { 
                            .company {
                                margin: 0 0 15px;
                            }

                            .socials { 
                                margin-bottom: 13px;
                            }

                            .copyright { 
                                margin-bottom: 15px;
                            }

                            .other { 
                                width: 100%;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                .policy { 
                                    margin: 0 0 22px;
                                }

                                .studio { 
                                    a {
                                        .text {

                                        }

                                        .icon {

                                        }
                                    }
                                }
                            }
                        }
                    }

                    .menu { 
                        margin-left: 65px;
                        .menu-holder { 

                            nav { 
    
                                ul { 
                                    column-count: 1;
                                    column-gap: 0;
                                    li {
                                        margin: 0 0 18.5px;
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    width: 451px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder { 
                padding: 39px 0 0;
                .left {
                    .general-info { 
                        .general-info-holder { 
                            .company {
                                a {

                                }
                            }

                            .socials { 

                            }

                            .copyright { 
                                font-size: 12px;
                            }

                            .other { 
                                font-size: 12px;
                                .policy { 

                                }

                                .studio { 

                                }
                            }
                        }
                    }

                    .menu { 
                        margin-left: 57px;
                        .menu-holder { 
                            nav {
                                ul {
                                    li {
                                        font-size: 14px;
                                        margin: 0 0 13px;
                                        a {

                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right { 
                    width: 325px;
                }
            }
        }
    }

    .call-order-form { 
        .call-order-form-holder { 
            .heading { 
    
            }
    
            .form {
                form {
                    .form-group {
                        width: 100%;
                        margin: 0;
                        .form-col {
                            padding: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
