@media (max-width: $screen-sm) {
    // sm-view
    .general-popup-container {
        max-width: 290px;
        padding: 15px 15px;
        .general-popup-container-holder {
            .success { 
                .icon { 
                    margin: 0 0 11px;
                    .icon-block { 
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                    }
                }
            }

            .heading {
                margin: 0 0 15px;
                .note {
                    font-size: 12px;
                    margin: 7px 0 0;
                }
            }

            .form { 

                form { 

                    .form-field { 
                        margin: 0 0 7px;
                        label.checkcontainer {
                            margin: 0 0 0;
                        }

                        label.rating {
                            margin: 7px 0 0;
                        }
                    }

                    .action { 
                        margin: 7px 0 0;
                    }

                }
            }
        }
    }

    .review-text-full {
        max-width: 290px;
        padding: 15px;
        .review-text-full-holder {
            .author { 
                margin: 0 0 12px;
                .image { 
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 20px;
                }

                .stats { 
                    .name { 
                        font-size: 14px;
                    }

                    .score { 
                        .stars { 
                            .star {
                                width: 13.5px;
                            }
                        }
                    }
                }
            }

            .review-text { 
                .text { 
                    p {
                        font-size: 10px;
                        &:not(:last-of-type) {
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
