@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .btn {
        font-size: 16px;
        padding: 11px 14px;
    }
}
