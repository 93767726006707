@media (max-width: $screen-sm) {
    // sm-view
    .slick-arrow {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
