.header-holder { 
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid $gray-lighter;
	.header-top { 
        display: inline-block;
        width: 100%;
        height: 50px;
        background: $bg-secondary;
        padding: 16px 0 0;
        box-sizing: border-box;
		.header-top-holder { 
            display: inline-block;
            width: 100%;
            .left { 
                float: left;

            }

            .right { 
                float: right;
                
            }

            .contacts { 
                float: left;
                .contacts-holder { 

                    .contacts-inner-block { 

                        .contact-info { 
                            float: left;
                            margin-right: 30px;
                            .contact-info-holder { 

                                a { 
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 18px;
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                    .icon { 
                                        float: left;
                                        height: 18px;
                                        margin-right: 11.5px;
                                        svg { 
                                            height: 100%;
                                            width: auto;
                                        }
                                    }

                                    .text { 
                                        float: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .work-hours { 
                float: left;
                margin: 0 0 0 -1px;
                .info { 

                    .info-holder { 
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        color: $gray-base;
                        .icon { 
                            float: left;
                            margin-right: 10px;
                            svg { 
                                height: 100%;
                                width: auto;
                            }
                        }

                        .text { 
                            float: left;
                        }
                    }
                }
            }

            .messengers { 

                .messenger { 
                    float: left;
                    margin-left: 31px;
                    &:first-of-type {
                        margin: 0;
                    }
                    a { 
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        .icon { 
                            float: left;
                            color: $gray-base;
                            margin-right: 10px;
                            height: 18px;
                            svg {
                                height: 100%;
                                width: auto;
                            }
                        }

                        .text { 
                            float: left;
                            color: $gray;
                        }
                    }
                }
            }
        }
	}

	.header-main { 
        display: inline-block;
        width: 100%;
		.header-main-holder { 
            display: inline-block;
            width: 100%;
            .left { 
                float: left;
            }

            .right { 
                float: right;
            }

            .mobile-menu { 
                float: left;
                padding: 22px 0 0;
                a { 
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    padding: 0;
                    background-color: $bg-secondary;
                    border-color: $bg-secondary;
                    color: $brand-primary;
                    svg {
                        vertical-align: middle;
                    }
                }
            }

            .logo { 
                float: left;
                .logo-holder { 
                    padding: 18px 0 0;
                    a { 

                        svg { 

                        }
                    }
                }
            }

            .menu { 
                float: left;
                margin-left: 40px;
                padding: 47px 0 0;
                .menu-holder { 

                    nav { 

                        ul { 
                            margin-left: -13px;
                            li { 
                                padding: 0 13px;
                                &.current { 

                                    a { 
                                        &:after {
                                            opacity: 1;
                                        }
                                    }
                                }
                                a { 
                                    display: inline-block;
                                    color: $gray-base;
                                    position: relative;
                                    &:hover {
                                        &:after {
                                            opacity: 1;
                                            transition: all 300ms linear;
                                        }
                                    }
                                    &:after {
                                        content: "";
                                        display: inline-block;
                                        width: 100%;
                                        position: absolute;
                                        left: 0;
                                        bottom: 3px;
                                        border-bottom: 1px solid $brand-primary;
                                        opacity: 0;
                                        transition: all 300ms linear;
                                    }
                                }
                            }

                            
                        }
                    }
                }
            }

            .action { 
                float: left;
                padding: 30px 0 0;
                .btn { 
                    width: 280px;
                    text-align: center;
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/header";
@import "../../media/mobile/includes/base/header";
