@media (max-width: $screen-lg) {
    // lg-md view

}

@media (max-width: $screen-md) {
    // md-view
    
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        line-height: $headings-line-height-md;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3 {
    
    }
    h4, .h4,
    h5, .h5,
    h6, .h6 {
    
    }

    h1, .h1 { font-size: $font-size-h1-md; }
    h2, .h2 { font-size: $font-size-h2-md; }
    h3, .h3 { 
        font-size: $font-size-h3-md; 
        line-height: 120%;
    }
    h4, .h4 { 
        font-size: $font-size-h4-md; 
        line-height: 120%;
    }
    h5, .h5 { font-size: $font-size-h5-md; }
    h6, .h6 { font-size: $font-size-h6-md; }

    p {
        font-size: $font-size-base-md;
        line-height: $line-height-computed-md;
        margin: 0 0 20px
    }

    ul,
    ol {
        font-size: $font-size-base-md;
        line-height: $line-height-computed-md;
        padding-left: 20px;
        margin-bottom: 23px;
        li {
            margin: 0 0 3px;
        }
    }

    .lead {
        margin-bottom: $line-height-computed-md;
        font-size: floor(($font-size-base-md * 1.15));
    }

    p.accent {
        font-size: $font-size-large-md;
        line-height: floor(($font-size-large-md * $line-height-base-md));
    }
    p.small {
        font-size: $font-size-small-md;
        line-height: floor(($font-size-small-md * $line-height-base-md));
    }

    small {
        font-size: $font-size-small-md;
    }
    
    dl {
        margin-top: 0; // Remove browser default
        margin-bottom: $line-height-computed-md;
      }
    dt,
    dd {
        line-height: $line-height-base-md;
    }

    blockquote {
        padding: floor(calc($line-height-computed-md / 2)) $line-height-computed-md;
        margin: 0 0 $line-height-computed-md;
        font-size: $blockquote-font-size;
    }

    address {
        margin-bottom: $line-height-computed-md;
        font-style: normal;
        line-height: $line-height-base-md;
    }

    .page-header {
        margin: 3px 0 31px;
    }
}
